 <template>
    <div class="domain-select">
        <h6>Search Term</h6>
        <vs-input icon-pack="feather" icon="icon-search" placeholder="Search" v-model="searchTerm" class="is-label-placeholder mt-0"/>
    </div>
 </template>
 
<script>
    export default {
        props:{
            searchTermValue:{
                type: String,
                required: true
            }, 
        },
        data() {
            return {
                
            }
        },
        computed: {
            searchTerm: {
                get() { return this.searchTermValue },
                set(searchTermValue) {this.$emit('input', searchTermValue)}
            }
        },
    }
</script>