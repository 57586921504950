<template>
    <vx-card class="h-full">
        <div class="card-title" :class="classOptional">
            <router-link v-if="isLink"
              :class="linkClass"
              :to="isLink"
            >{{linkLabel}}</router-link>
            <h2>{{chartTitle}}</h2>
            <!-- <vs-button
                @click="popupActive = true"
                color="gray"
                type="flat"
                icon-pack="feather"
                icon="icon-help-circle"
                class="p-0 help-btn"
            ></vs-button> -->
        </div>
        <vue-apex-charts :type="chartType" :height="chartHeight" :options="directVSReferralVisitorsTimelineChart.chartOptions" :series="directVSReferralVisitorsTimelineChart.series"></vue-apex-charts>
    </vx-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        VueApexCharts,
    },
    props:{
        chartTitle:{
            required: true
        },
        chartType:{
            required: true
        },
        chartHeight:{
            required: true
        },
        seriesNameOne:{
            required: true
        },
        seriesDataOne:{
            required: true
        },
        seriesNameTwo:{
            required: true
        },
        seriesDataTwo:{
            required: true
        },
        seriesNameThree:{
            required: true
        },
        seriesDataThree:{
            required: true
        },
        optionsXaxis:{
            required: true
        },
        classOptional:{

        },
        isLink:{
            
        },
        linkLabel:{
            
        },
        linkClass:{
            
        }
    },
    watch : {
        seriesDataOne : function () {
            this.directVSReferralVisitorsTimelineChart.series = [{
                    name: this.seriesNameOne,
                    type: "column",
                    data: this.seriesDataOne
                },
                {
                    name: this.seriesNameTwo,
                    type: "area",
                    data: this.seriesDataTwo
                },
                {
                    name: this.seriesNameThree,
                    type: "line",
                    data: this.seriesDataThree
                }
            ];
            this.directVSReferralVisitorsTimelineChart.chartOptions = {
                ...this.directVSReferralVisitorsTimelineChart.chartOptions,
                ...{
                    xaxis: {
                        type: "datetime",
                        categories: this.optionsXaxis,
                        labels: {
                            hideOverlappingLabels: true,
                            showDuplicates: false,
                            format: "M/d"
                        }
                    }
                }
            };
        }
    },
    data (){
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        return {
            directVSReferralVisitorsTimelineChart: {
                series: [{
                        name: this.seriesNameOne,
                        type: "column",
                        data: []
                    },
                    {
                        name: this.seriesNameTwo,
                        type: "area",
                        data: []
                    },
                    {
                        name: this.seriesNameThree,
                        type: "line",
                        data: []
                    }
                ],
                chartOptions: {
                    colors: [
                        token[uid].themeDetail.tempChartColor1,
                        token[uid].themeDetail.tempChartColor2,
                        token[uid].themeDetail.tempChartColor3
                    ],
                    stroke: {
                        width: [0, 3, 3],
                        curve: "smooth"
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: "30%",
                            endingShape: "rounded"
                        }
                    },
                    fill: {
                        type: "solid",
                        opacity: [1, 0.2, 1],
                        gradient: {
                            type: "vertical",
                            shadeIntensity: 0,
                            opacityFrom: 1,
                            opacityTo: 0.2,
                            stops: [0, 90, 100]
                        }
                    },
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: "datetime",
                        categories: [],
                        labels: {
                            hideOverlappingLabels: true,
                            showDuplicates: false,
                            format: "M/d"
                        }
                    },
                    legend: {
                        position: "bottom",
                        fontSize: "12px",
                        fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
                        itemMargin: {
                            horizontal: 20,
                            vertical: 10
                        },
                        onItemClick: {
                            toggleDataSeries: true
                        },
                        markers: {
                            width: 12,
                            height: 12,
                            radius: 2
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: "#f2f2f2",
                        strokeDashArray: 3,
                        position: "back",
                        xaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    yaxis: [
                        {
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: token[uid].themeDetail.tempChartColor1,
                            },
                            labels: {
                                formatter: function (val) {
                                    return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                },
                                style: {
                                    color: token[uid].themeDetail.tempChartColor1,
                                }
                            },
                            title: {
                                text: this.seriesNameOne,
                                style: {
                                    color: token[uid].themeDetail.tempChartColor1,
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        },
                        {
                            seriesName: this.seriesNameTwo,
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: token[uid].themeDetail.tempChartColor2
                            },
                            labels: {
                                formatter: function (val) {
                                    return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                },
                                style: {
                                    color: token[uid].themeDetail.tempChartColor2
                                }
                            },
                            title: {
                                text: this.seriesNameTwo,
                                style: {
                                    color: token[uid].themeDetail.tempChartColor2
                                }
                            }
                        },
                        {
                            seriesName: this.seriesNameThree,
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: token[uid].themeDetail.tempChartColor3
                            },
                            labels: {
                                formatter: function (val) {
                                    return parseInt(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                },
                                style: {
                                    color: token[uid].themeDetail.tempChartColor3
                                }
                            },
                            title: {
                                text: this.seriesNameThree,
                                style: {
                                    color: token[uid].themeDetail.tempChartColor3
                                }
                            }
                        }
                    ],
                    tooltip: {
                        shared: true,
                        intersect: false,
                        x: {
                            show: true,
                            format: "M/d"
                        },
                        y:{
                            formatter: function (val) {
                                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            },
                        }
                    }
                }
            },
        }
    }    
}
</script>