<template>
  <div>
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info mb-8">          
          <!-- Domain -->          
          <select-filter
            labelname="Domain"
            :options="domainOptions"
            optionsName="domainName"
            :value="domainSelected"
            @input="(newDomain) => {domainSelected = newDomain}"
          ></select-filter>
          <!-- Campaign -->
          <select-filter
            labelname="Campaign"
            :options="campaignOptions"
            optionsName="campaignName"
            :value="campaignSelected"
            @input="(newCampaign) => {campaignSelected = newCampaign}"
          ></select-filter>
          <!-- Date Picker -->
          <date-range-picker
            :fromDateValue="fromDate"
            :toDateValue="toDate"
            @inputFromDate="(newFromDate) => {fromDate = newFromDate}"
            @inputToDate="(newToDate) => {toDate = newToDate}"
          ></date-range-picker>
          <vs-button            
            type="filled"
            @click="updateData()"
            class="small-btn bg-actionbutton"
          >{{ LabelConstant.buttonLabelRunQuery }}</vs-button>
        </div>
      </div>
    </div>
    <!-- Email Chart -->
    <div class="vx-row" id="v-step-0">
      <div class="vx-col w-full mb-8">
        <three-xaxis-chart
          :chartTitle="'Email Campaign Overview'"
          :chartType="'line'"
          :chartHeight="'400'"

          :seriesNameOne="'First Time Email Visitors'"
          :seriesDataOne="emailCampaignOverviewChartSeriesDataOne"
          
          :seriesNameTwo="'Unique Email Visitors'"
          :seriesDataTwo="emailCampaignOverviewChartSeriesDataTwo"

          :seriesNameThree="'Page Views'"
          :seriesDataThree="emailCampaignOverviewChartSeriesDataThree"
          
          :optionsXaxis="emailCampaignOverviewChartOptionsXaxis"
        ></three-xaxis-chart>
      </div>
      <div class="vx-col w-full mb-8">
        <vx-card class="h-full">


         <!-- Table Title -->
         <div class="table-title">
            <h2>Email Visitors</h2>
            <div class="actions-button">
               <!-- <vx-tooltip color="secondary" text="CSV" class="float-left">
               <vs-button color="secondary" type="filled">
                  <img src="../assets/images/csv.svg">
               </vs-button>
               </vx-tooltip>-->
               <vx-tooltip color="actionbutton" text="EXCEL" class="float-left ml-0">
                  <vs-button  type="filled" class="bg-actionbutton">
                     <download-excel
                        class="btn btn-default"
                        :data="excelData"
                        :exportFields="excelFields"
                        worksheet="Email Campaign"
                        name="Email Campaign.xls"
                     ><img src="@/assets/images/excel.svg" /></download-excel>
                  </vs-button>
               </vx-tooltip>
            </div>
         </div>
         <div class="common-table">      
            <!--Table Select Row-->
            <div class="table-row-select">
               <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
               <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item,index) in recordList"
               />
               </vs-select>
            </div>
            <vs-table
               id="emailCampaignData"
               :data="emailVisitorsData"
               search
               :max-items="perPage"
               pagination
               ref="tableData"
            >
               <template slot="thead">
                <vs-th></vs-th>
                <vs-th sort-key="Key">Email</vs-th>
                <vs-th
                    sort-key="Value"
                    align="center"
                    width="140"
                    class="text-center justify-center"
                >Page Views</vs-th>
               </template>
               <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td width="50">
                      <vs-button
                      @click="detailspopupActive=true, getCompanyDetailsByEmail(tr.Key)"
                      title="View Details"                    
                      type="filled"
                      icon-pack="feather"
                      icon="icon-search"
                      class="mr-0"
                      color="primary"
                      ></vs-button>
                    </vs-td>
                    <vs-td :data="tr.Key">{{tr.Key}}</vs-td>
                    <vs-td align="center" width="140" :data="tr.Value">{{tr.Value}}</vs-td>
                </vs-tr>
               </template>
            </vs-table>
            <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{emailVisitorsData.length|tableRecordNumberFormatter}} entries</p>
            <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{emailVisitorsData.length|tableRecordNumberFormatter}} entries)</p>
            <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>
         </div>

          
          
        </vx-card>
      </div>
    </div>

    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
    <!-- Company Details popup -->
    <company-details-modal
      :latestVisitorId="latestVisitorId"
      :searchLeadDetailsData="searchLeadDetails"
      @inputSearchLeadDetails="(newSearchLeadDetails) => {searchLeadDetails = newSearchLeadDetails}"
      :pageViewsData="pageViewsCompany"
      @inputPageViews="(newpageViewsCompany) => {pageViewsCompany = newpageViewsCompany}"
      :keywordsData="keywords"
      @inputKeywords="(newKeywords) => {keywords = newKeywords}"
      :allVisitorUniqueDetailShortData="allVisitorUniqueDetailShort"
      @inputAllVisitorUniqueDetailShort="(newAllVisitorUniqueDetailShort) => {allVisitorUniqueDetailShort = newAllVisitorUniqueDetailShort}"
      :detailspopupActive="detailspopupActive"
      @close="closepopup"
      @getCompanyDetails="getCompanyDetailsById(latestVisitorId)"
    ></company-details-modal>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

/* Filter */
import SearchFilter from "../../components/filter/SearchFilter";
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import HelpModal from "../../components/modal/HelpModal";
import CompanyDetailsModal from "../../components/modal/CompanyDetailsModal";

/* chart */
import VueApexCharts from "vue-apexcharts";

/* export excel */
import JsonExcel from "vue-json-excel";
import ThreeXaxisChart from '../../components/charts/ThreeXaxisChart.vue';
//import Cookie from "js-cookie";

export default {
  components: {
    VxCard,
    SearchFilter,
    SelectFilter,
    DateRangePicker,
    HelpModal,
    CompanyDetailsModal,
    VueApexCharts,
    downloadExcel: JsonExcel,
    ThreeXaxisChart
  },

  data() {
    return {
     
      /* Help Popup */
      popupActive: false,

      /* Domain Filter */
      domainSelected: { domainName: "All" },
      //Domain dropdown options
      domainOptions: [],

      /* Campaign Filter */
      campaignSelected: { campaignName: "All" },
      //Domain dropdown options
      campaignOptions: [],

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      /* Email Campaign Overview Chart */
      emailCampaignOverviewChartSeriesDataOne: [],
      emailCampaignOverviewChartSeriesDataTwo: [],
      emailCampaignOverviewChartSeriesDataThree: [],
      emailCampaignOverviewChartOptionsXaxis: [],
      
      //Form Captures Table data
      emailVisitorsData: [],

      perPage: 5,
      recordList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],

      //Company details popup
      detailspopupActive: false,
      latestVisitorId: "",
      searchLeadDetails: {},
      allVisitorUniqueDetailShort: [],
      pageViewsCompany: {},
      keywords: [],

      // excel sheet fields heading
      excelFields: {
        Email: "Email",
        "Page Views": "PageViews"
      },
      excelData: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  mounted() {
    let that = this;
    //Filter Location Breakdown datatable record based on search parameter for excel
    setTimeout(() => {
      document
        .querySelector("#emailCampaignData .input-search")
        .addEventListener("keyup", function() {
          that.excelData = that.filterExport(true);
        });
    }, 1000);
  },
  created() {
    this.setFromDate();
    this.setToDate();

    this.getZoneListForFilter();
    this.getEmailCampaignListForFilter();
    this.getPageViewsUniqueVisitorsFirstTimeVisitorsByDay();
    this.getVisitorEmailsWithPageViewCount();
  },
  methods: {
    /* Popup Close */
    closepopup() {
      (this.popupActive = false), (this.detailspopupActive = false);
    },
    //On clicking Update Dashboard button will update all data
    updateData() {
      this.$refs.tableData.currentx = 1
      this.$refs.tableData.searchx = ""
      this.getPageViewsUniqueVisitorsFirstTimeVisitorsByDay();
      this.getVisitorEmailsWithPageViewCount();
    },
    //Get latest visitor company details
    getCompanyDetailsByEmail(email) {
      let getVisitorCompanyIdByEmailParams = {
        Email: email
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/SearchLead/GetVisitorCompanyIdByEmail",
          getVisitorCompanyIdByEmailParams
        )
        .then(response => {
          let data = response.data;
          this.latestVisitorId = data;
          this.getSearchLeadDetail(data);
          this.getAllVisitorUniqueDetailShort(data);
          this.getPageViews(data);
          this.getKeywords(data);
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getCompanyDetailsById(id) {
      this.latestVisitorId = id;
      this.getSearchLeadDetail(id);
      this.getAllVisitorUniqueDetailShort(id);
      this.getPageViews(id);
      this.getKeywords(id);
    },

    //Get Domain
    getZoneListForFilter() {
      this.$vs.loading();
      this.axios
        .get("/ws/Zone/GetZoneListForFilter")
        .then(response => {
          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            this.domainOptions.push({
              domainName: data[i]
            });
          }

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Get Campaign
    getEmailCampaignListForFilter() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailCampaignDashboard/GetEmailCampaignListForFilter")
        .then(response => {
          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            this.campaignOptions.push({
              campaignName: data[i]
            });
          }

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Email Campaign Overview
    getPageViewsUniqueVisitorsFirstTimeVisitorsByDay() {
      let getPageViewsUniqueVisitorsFirstTimeVisitorsByDayParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Email_Campaign: this.campaignSelected.campaignName,
        Only_Companies: true,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/EmailCampaignDashboard/GetPageViewsUniqueVisitorsFirstTimeVisitorsByDay",
          getPageViewsUniqueVisitorsFirstTimeVisitorsByDayParams
        )
        .then(response => {
          let data = response.data;
          let x = [];
          let FirstGroup = [];
          let SecondGroup = [];
          let ThirdGroup = [];

          // let scale = 1
          // let dataSize = data.length
          data.forEach(data => {
            x.push(data.x);

            FirstGroup.push(data.Companies_Identified);
            SecondGroup.push(data.Unique_Visitors);
            ThirdGroup.push(data.Page_Views);
          });

          this.emailCampaignOverviewChartSeriesDataOne = FirstGroup
          this.emailCampaignOverviewChartSeriesDataTwo = SecondGroup
          this.emailCampaignOverviewChartSeriesDataThree = ThirdGroup
          this.emailCampaignOverviewChartOptionsXaxis = x

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Email Visitors
    getVisitorEmailsWithPageViewCount() {
      let getVisitorEmailsWithPageViewCountParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Email_Campaign: this.campaignSelected.campaignName,
        Only_Companies: true,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/EmailCampaignDashboard/GetVisitorEmailsWithPageViewCount",
          getVisitorEmailsWithPageViewCountParams
        )
        .then(response => {
          let data = response.data;
          this.emailVisitorsData = data;
          this.excelData = this.filterExport();
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get latest visitor popup details
    getSearchLeadDetail(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/SearchLead/GetSearchLeadDetail?id=" + id)
        .then(response => {
          let data = response.data;
          this.searchLeadDetails = data;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get latest visitor popup details
    getAllVisitorUniqueDetailShort(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/SearchLead/GetAllVisitorUniqueDetailShort?id=" + id)
        .then(response => {
          let data = response.data;
          this.allVisitorUniqueDetailShort = data;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get latest visitor popup details
    getPageViews(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/SearchLead/GetPageViews?id=" + id)
        .then(response => {
          let data = response.data;
          this.pageViewsCompany = data;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //get latest visitor popup details
    getKeywords(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/SearchLead/GetKeywords?id=" + id)
        .then(response => {
          let data = response.data;
          this.keywords = data;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    filterExport(search = false) {
      let data = [];
      let localListingData = !search
        ? this.emailVisitorsData
        : this.$refs.tableData.datax;

       for (var i = 0; i < localListingData.length; i++) {
        data.push({
          Email: localListingData[i].Key,
          PageViews: localListingData[i].Value
        });
      }
      return data;
    }
  },
  computed: {
    /**
		 * Showing Entries details
		 * isTableRecordEntriesDisplay()
		 * isSearch()
		 * showingDataFrom()
		 * showingDataTo()
		 * totalSearchedData()
     * showingSearchedDataTo()
		 *  */
		isTableRecordEntriesDisplay(){
			if(this.emailVisitorsData.length > 0){
				if(this.$refs.tableData.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
    isSearch: function () {
      if(this.emailVisitorsData.length > 0){
        if(this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == ""){
            return false
        }else{
            return true
        }
      }
    },
    showingDataFrom: function () {
      if(this.emailVisitorsData.length > 0){
        return ((this.$refs.tableData.currentx - 1) * this.perPage) + 1 
      }
    },
    showingDataTo: function () {
      if(this.emailVisitorsData.length > 0){
        let totalPages = this.$refs.tableData.getTotalPages
        if(totalPages == this.$refs.tableData.currentx){
            return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
        }else{
            return ((this.$refs.tableData.currentx) * this.perPage)
        }
      }
    },
    totalSearchedData: function () {
      if(this.emailVisitorsData.length > 0){
        return this.$refs.tableData.queriedResults.length
      }
    },
    showingSearchedDataTo: function () {
      if(this.emailVisitorsData.length > 0){
        let totalPages = this.$refs.tableData.getTotalPagesSearch
        if(totalPages == this.$refs.tableData.currentx){
            return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
        }else{
            return ((this.$refs.tableData.currentx) * this.perPage)
        }
      }
    }
  }
};
</script>