<template>
  <div>
    <!-- Tag Prospect -->
    <vs-popup class="tag-info" title="Tag Prospect" :active.sync="tagpopupActive">
      <template>
        <div class="vx-row mt-2">
          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input
              class="w-full"
              label-placeholder="Company Name"
              v-model="tagProspectDetails.Company_Name"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input
              class="w-full"
              label-placeholder="Domain Name"
              v-model="tagProspectDetails.Domain_Name"
            />
          </div>

          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input
              class="w-full"
              label-placeholder="Address 1"
              v-model="tagProspectDetails.Address1"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4" v-if="isShowExtendedField">
            <vs-input
              class="w-full"
              label-placeholder="Address 2"
              v-model="tagProspectDetails.Address2"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input class="w-full" label-placeholder="City" v-model="tagProspectDetails.City" />
          </div>

          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input class="w-full" label-placeholder="State" v-model="tagProspectDetails.State" />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input class="w-full" label-placeholder="Zip" v-model="tagProspectDetails.Zip" />
          </div>

          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input
              class="w-full"
              label-placeholder="Industry"
              v-model="tagProspectDetails.Industry"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input
              class="w-full"
              label-placeholder="Revenue"
              v-model="tagProspectDetails.Revenue"
            />
          </div>

          <div class="vx-col w-full md:w-1/2 mb-4" v-if="isShowExtendedField">
            <vs-input
              class="w-full"
              label-placeholder="Salutation"
              v-model="tagProspectDetails.Salutation"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input
              class="w-full"
              label-placeholder="First Name"
              v-model="tagProspectDetails.First_Name"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input
              class="w-full"
              label-placeholder="Last Name"
              v-model="tagProspectDetails.Last_Name"
            />
          </div>

          <div class="vx-col w-full md:w-1/2 mb-4" v-if="isShowExtendedField">
            <vs-input class="w-full" label-placeholder="Title" v-model="tagProspectDetails.Title" />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4" v-if="isShowExtendedField">
            <v-select
              label="genderName"
              placeholder="Gender"
              :options="gender"
              v-model="tagProspectDetails.Gender"
            />
          </div>

          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input class="w-full" label-placeholder="Email" v-model="tagProspectDetails.Email" />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4">
            <vs-input
              class="w-full"
              label-placeholder="Primary Phone"
              v-model="tagProspectDetails.Phone_Primary"
            />
          </div>

          <div class="vx-col w-full md:w-1/2 mb-4" v-if="isShowExtendedField">
            <vs-input
              class="w-full"
              label-placeholder="Secondary Phone"
              v-model="tagProspectDetails.Phone_Secondary"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-4" v-if="isShowExtendedField">
            <vs-input class="w-full" label-placeholder="Fax" v-model="tagProspectDetails.Fax" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-textarea class="mt-4 mb-0" label="Note" v-model="tagProspectDetails.Notes" />
          </div>
        </div>
      </template>
      <div class="float-right sm:w-auto w-full mt-6 mb-8">
        <vx-tooltip          
          text="Show Extended Fields"
          class="float-left mr-0 sm:mr-4 show-hide"
          v-if="!isShowExtendedField"
          color="primary"
        >
          <vs-button            
            type="filled"
            icon-pack="feather"
            icon="icon-eye"
            @click="isShowExtendedField = !isShowExtendedField"
            color="primary"
          ></vs-button>
        </vx-tooltip>

        <vx-tooltip
          color="primary"
          text="Hide Extended Fields"
          class="float-left mr-0 sm:mr-4 show-hide"
          v-if="isShowExtendedField"
        >
          <vs-button            
            type="filled"
            icon-pack="feather"
            icon="icon-eye-off"
            @click="isShowExtendedField = !isShowExtendedField"
            color="primary"
          ></vs-button>
        </vx-tooltip>

        <vs-button
          @click="tagpopupActive = false, updateVisitorUniqueDetail()"          
          type="filled" class="mr-0 mb-2 sm:mb-0 sm:mr-4" color="primary">
          {{ LabelConstant.buttonLabelUpdateProspect }}</vs-button>

        <vs-button
          @click="tagpopupActive = false, isShowExtendedField = false"
          color="grey"
          type="border"
          class="sm:w-auto w-full grey-btn"
        >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>

    <!-- Forword Email -->
    <vs-popup class="tag-info" title="Forward Prospect" :active.sync="forwardopupActive">
      <template>
        <div class="vx-row">
          <div class="vx-col input-text w-full">
            <div class="popup-icon">
              <img src="../../../src/assets/images/forward-email.png" />
            </div>
            <form data-vv-scope="forwardProspectForm">
              <vs-input
                type="email"
                class="w-full"
                label-placeholder="Email"
                v-model="forwardProspectEmail"
                v-validate="'required|email'"
                name="forwardProspectEmail"
              />
              <span
                class="text-danger text-sm"
              >{{ errors.first('forwardProspectForm.forwardProspectEmail') }}</span>
              <p>Please enter the email address of the person you wish to receive this prospect.</p>
            </form>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button        
          type="filled"
          @click="forwardProspect()"
          class="bg-actionbutton"
        >{{ LabelConstant.buttonLabelForwardProspect }}</vs-button>
        <vs-button
          @click="cancelForwardProspect()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
        >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    <!-- Comany Details -->
    <vs-popup
      class="company-details-modal close-open"
      :title="searchLeadDetails.Company_Name"
      :active.sync="detailspopupActive"
      >
      <vs-button
        @click="closepopup"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
      >{{ LabelConstant.buttonLabelClose }}</vs-button>
      <div class="company-top-tags">
        <ul class="tag-list">
          <li>
            <vx-tooltip color="success" text="Update this visitors information">
              <vs-button
                @click="tagpopupActive=true, getSearchLeadDetail()"
                color="success"
                type="border"
                class="small-btn"
              >{{ LabelConstant.buttonLabelTag }}</vs-button>
            </vx-tooltip>
          </li>
          <li>
            <vx-tooltip color="success" text="Lookup this company in Google">
              <a class="google-tag small-btn" :href="'http://www.google.com/search?hl=en&q='+searchLeadDetails.Company_Name+'&btnG=Google+Search'" 
              target="_blank">Google</a>
            </vx-tooltip>
          </li>
          <li v-if="isCRMEnabled">
            <vx-tooltip color="success" text="Send this company to your CRM">
              <vs-button
                color="success"
                type="border"
                class="small-btn"
                @click="sendToCRM(searchLeadDetails.Id)"
              >{{ LabelConstant.buttonLabelSendToCRM }}</vs-button>
            </vx-tooltip>
          </li>
        </ul>
        <ul class="tag-list">
          <li v-if="searchLeadDetails.Company_is_isp">
            <vx-tooltip color="success" text="Mark this visitor as a business">
              <vs-button
                color="success"
                type="border"
                class="small-btn"
                @click="updateISPtoCompany()"
              >{{ LabelConstant.buttonLabelSetAsCompany }}</vs-button>
            </vx-tooltip>
          </li>
          <li v-else>
            <vx-tooltip color="success" text="Mark this visitor as an ISP">
              <vs-button
                color="success"
                type="border"
                class="small-btn"
                @click="updateCompanytoISP()"
              >{{ LabelConstant.buttonLabelSetAsISP }}</vs-button>
            </vx-tooltip>
          </li>
          <li>
            <vx-tooltip color="success" text="Email this information to someone">
              <vs-button
                @click="forwardopupActive=true"
                color="success"
                type="border"
                class="small-btn"
              >{{ LabelConstant.buttonLabelForward }}</vs-button>
            </vx-tooltip>
          </li>
          <li v-if="!searchLeadDetails.On_Watch_List">
            <vx-tooltip color="success" text="Add this company to your watch list">
              <vs-button
                color="success"
                type="border"
                class="small-btn"
                @click="updateWatchList(searchLeadDetails.Company_Name)"
              >{{ LabelConstant.buttonLabelWatch }}</vs-button>
            </vx-tooltip>
          </li>
          <li v-if="!searchLeadDetails.On_Exclude_List">
            <vx-tooltip color="success" text="Add this company to your exclude list">
              <vs-button
                color="success"
                type="border"
                class="small-btn"
                @click="updateExcludeList(searchLeadDetails.Company_Name)"
              >{{ LabelConstant.buttonLabelExclude }}</vs-button>
            </vx-tooltip>
          </li>
        </ul>
      </div>
      <div class="user-details">
        <vs-button
          v-if="detailsShow"
          :title="LabelConstant.buttonTitleShowExtendedComapnyDetails"
          @click="detailsShow = !detailsShow"        
          type="filled"
          icon-pack="feather"
          icon="icon-eye-off"
          class="toggle-comapny-details"
          color="primary"
        ></vs-button>
        <vs-button
          v-if="!detailsShow"
          :title="LabelConstant.buttonTitleShowExtendedComapnyDetails"
          @click="detailsShow = !detailsShow"          
          type="filled"
          icon-pack="feather"
          icon="icon-eye"
          class="toggle-comapny-details"
          color="primary"
        ></vs-button>
        <ul v-if="detailsShow">
          <li>
            <feather-icon icon="UserIcon" class="text-primary"></feather-icon>
            <div>
              <h3>
                Visitor
                <span
                  class="small-font-visitor"
                  v-if="searchLeadDetails.On_Watch_List"
                >( On Watch List )</span>
                <span
                  class="small-font-visitor"
                  v-if="searchLeadDetails.On_Exclude_List"
                >( On Exclude List )</span>
              </h3>
              <span>{{searchLeadDetails.Company_Name}}</span>
              <span v-if="searchLeadDetails.Has_Email">{{searchLeadDetails.Email}}</span>
              <span v-if="searchLeadDetails.First_Name && searchLeadDetails.First_Name != 'No First Name' &&searchLeadDetails.First_Name && searchLeadDetails.Last_Name != 'No Last Name'">{{searchLeadDetails.First_Name}} {{searchLeadDetails.Last_Name}}</span>
            </div>
          </li>
          <li>
            <feather-icon icon="MapPinIcon" class="text-primary"></feather-icon>
            <div>
              <h3>Visitor's Location</h3>
              <span>{{searchLeadDetails.City}}, {{searchLeadDetails.State}} {{searchLeadDetails.Zip}} ({{searchLeadDetails.Country}})</span>
            </div>
          </li>
          <li>
            <feather-icon icon="CalendarIcon" class="text-primary"></feather-icon>
            <div>
              <h3>Date / Time</h3>
              <span>{{searchLeadDetails.Visit_Date}}</span>
            </div>
          </li>
          <li>
            <feather-icon icon="LinkIcon" class="text-primary"></feather-icon>
            <div>
              <h3>Source and Keywords Used</h3>
              <span>{{searchLeadDetails.Referrer}} : {{searchLeadDetails.Keyword}}</span>
            </div>
          </li>
        </ul>
      </div>
      <!-- Tab -->
      <vs-tabs direction="vertical" :value="activeTab">
        <vs-tab label="Company Info" @click="activeTab = 0">
          <div class="tab-text">
            <div class="user-details">
              <div class="vx-row">
                <div class="vx-col lg:w-1/2 w-full">
                  <ul>
                    <li v-if="searchLeadDetails.First_Name && searchLeadDetails.First_Name != 'No First Name' &&searchLeadDetails.First_Name && searchLeadDetails.Last_Name != 'No Last Name'">
                      <feather-icon icon="UserIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Visitor Name</h3>
                        <span>{{searchLeadDetails.First_Name}} {{searchLeadDetails.Last_Name}}</span>
                      </div>
                    </li>                    
                    <li v-if="searchLeadDetails.Phone_Primary">
                      <feather-icon icon="PhoneIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Visitor Phone</h3>
                        <span>{{searchLeadDetails.Phone_Primary}}</span>
                      </div>
                    </li>                                 
                    <li>
                      <feather-icon icon="CalendarIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Date / Time</h3>
                        <span>{{searchLeadDetails.Visit_Date}}</span>
                      </div>
                    </li>                    
                    <li>
                      <feather-icon icon="GlobeIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Domain Name</h3>
                        <span>{{searchLeadDetails.Domain_Name}}</span>
                      </div>
                    </li>                    
                    <li>
                      <feather-icon icon="MapPinIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Visitor's Location</h3>
                        <span>{{searchLeadDetails.Address1}}</span>
                        <span>{{searchLeadDetails.Address2}}</span>
                        <span>{{searchLeadDetails.City}}, {{searchLeadDetails.State}} {{searchLeadDetails.Zip}} ({{searchLeadDetails.Country}})</span>
                      </div>
                    </li>                    
                    <li>
                      <feather-icon icon="EyeIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>First Page Visited</h3>
                        <span class="visit-link">{{searchLeadDetails.First_Page}}</span>
                      </div>
                    </li>                   
                  </ul>
                </div>
                <div class="vx-col lg:w-1/2 w-full">
                   <ul>                    
                    <li v-if="searchLeadDetails.Has_Email">
                      <feather-icon icon="MailIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Visitor Email</h3>
                        <span>{{searchLeadDetails.Email}}</span>
                      </div>
                    </li>                     
                    <li v-if="searchLeadDetails.Industry">
                      <feather-icon icon="HomeIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Industry</h3>
                        <span>{{searchLeadDetails.Industry}}</span>
                      </div>
                    </li>                                                
                    <li>
                      <feather-icon icon="LinkIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Source and Keywords Used</h3>
                        <span>{{searchLeadDetails.Referrer}} : {{searchLeadDetails.Keyword}}</span>
                      </div>
                    </li>
                    <li>
                      <feather-icon icon="UserIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Visitor Identity</h3>
                        <span>{{searchLeadDetails.IP_Address}}</span>
                      </div>
                    </li>                    
                    <li>
                      <feather-icon icon="BarChartIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Revenue</h3>
                        <span>{{searchLeadDetails.Revenue}}</span>
                      </div>
                    </li>                    
                    <li>
                      <feather-icon icon="FileIcon" class="text-primary"></feather-icon>
                      <div>
                        <h3>Notes</h3>
                        <span>{{searchLeadDetails.Notes}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Pages Viewed" @click="activeTab = 1">
          <div class="tab-text page-view">
            <div class="filter-data" v-if="filterShow">
              <div class="filter-select mb-3">
                <vs-button                  
                  type="filled"
                  icon-pack="feather"
                  icon="icon-filter"
                  class="small-btn float-left bg-actionbutton"
                  @click="getFilteredPageViews()"
                >{{ LabelConstant.buttonLabelFilterVisitors }}</vs-button>
                <!-- <vs-button color="primary" type="border" class="ml-2 small-btn">Select All</vs-button> -->
                <!-- <vs-button color="primary" type="border" class="ml-2 small-btn">Unselect All</vs-button> -->
              </div>
              <vs-table
                class="summary-table filter-table"
                ref="tableDataRecentReviews"
                multiple
                v-model="selectedUniqueVisitors"
                :data="allVisitorUniqueDetailShort"
              >
                <template slot="thead">
                  <vs-th>Visitor Id</vs-th>
                  <vs-th>First Visit</vs-th>
                  <vs-th>Source</vs-th>
                  <vs-th>Keywords</vs-th>
                  <vs-th>Campaign Name</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].Visitor_Id[0]">{{data[indextr].Visitor_Id[0]}}</vs-td>
                    <vs-td :data="data[indextr].First_Visit">{{data[indextr].First_Visit}}</vs-td>
                    <vs-td :data="data[indextr].Referrer">{{data[indextr].Referrer}}</vs-td>
                    <vs-td :data="data[indextr].Keyword">{{data[indextr].Keyword}}</vs-td>
                    <vs-td
                      :data="data[indextr].Source_Campaign_Name"
                    >{{data[indextr].Source_Campaign_Name}}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <span
                class="bottom-count"
              >Total Items: {{allVisitorUniqueDetailShort.length}} (Selected Items: {{selectedUniqueVisitors.length}})</span>
            </div>
            <div class="tab-title">
              <h3 class="py-2">
                Pages Viewed - Detailed
                <span class="text-primary">{{uniqueVisitorCount}} Unique Visitors</span>
              </h3>
              <vs-button                
                type="filled"
                icon-pack="feather"
                icon="icon-filter"
                @click="filterShow = !filterShow"
                class="small-btn bg-actionbutton"
              >{{ LabelConstant.buttonLabelFilterVisitors }}</vs-button>
            </div>
            <ul>
              <li v-for="(pageView, index) in pageViews.Sessions" :key="index">
                <h4>Session: {{pageView.Date_Created}} <span>Visitor Id: {{pageView.Visitor_Id}}</span></h4>
                <table>
                  <thead>
                    <tr>
                      <th>Pages Viewed</th>
                      <th width="130px">Time Spent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, indexInner) in pageView.Page_Views" :key="indexInner">
                      <td>{{item.Page_Url}}</td>
                      <td width="130px">{{item.Time_Spent}}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </div>
        </vs-tab>
        <vs-tab label="Page View Summary" @click="activeTab = 2">
          <div class="tab-text page-view page-summary">
            <div class="tab-title">
              <h3>Click Path Summary</h3>
            </div>
            <vs-table class="summary-table" :data="pageViews.Page_Counts">
              <template slot="thead">
                <vs-th>Pages Viewed</vs-th>
                <vs-th width="80">Count</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].Page_Url">{{ data[indextr].Page_Url }}</vs-td>
                  <vs-td
                    width="80"
                    align="center"
                    :data="data[indextr].Page_Count"
                  >{{ data[indextr].Page_Count }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-tab>
        <vs-tab label="Research" @click="activeTab = 3">
          <div class="tab-text">
            <div class="tab-title">
              <h3>Company Research (click for company lookup)</h3>
            </div>
            <ul class="research-list">
              <li>
                <a
                  :href="'http://www.google.com/search?hl=en&q='+searchLeadDetails.Company_Name+'&btnG=Google+Search'"
                  target="_blank"
                >
                  <img src="@/assets/images/google.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'https://www.linkedin.com/search/results/people/?keywords='+searchLeadDetails.Company_Name+'&origin=FACETED_SEARCH'"
                  target="_blank"
                >
                  <img src="@/assets/images/linkedin.png" />
                </a>
              </li>
              <li>
                <!-- <a
                  :href="'http://www.facebook.com/search.php?q='+searchLeadDetails.Company_Name+'&type=users'"
                  target="_blank"
                > -->
                <a :href="'https://www.facebook.com/search/top?q='+searchLeadDetails.Company_Name" target="_blank"> 
                  <img src="@/assets/images/facebook.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'https://twitter.com/search?q='+searchLeadDetails.Company_Name+'&src=typd'"
                  target="_blank"
                >
                  <img src="@/assets/images/twitter.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'http://www.youtube.com/results?search_query='+searchLeadDetails.Company_Name"
                  target="_blank"
                >
                  <img src="@/assets/images/youtube.png" />
                </a>
              </li>
              <!-- <li>
                <a
                  :href="'http://www.hoovers.com/search/company-search-results/100003765-1.html?type=company&term='+searchLeadDetails.Company_Name"
                  target="_blank"
                >
                  <img src="@/assets/images/hoovers.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'http://www.manta.com/world/?pt=&search_source=nav&search='+searchLeadDetails.Company_Name"
                  target="_blank"
                >
                  <img src="@/assets/images/manta.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'http://www.slideshare.net/search/slideshow?searchfrom=header&q='+searchLeadDetails.Company_Name+'&type=users'"
                  target="_blank"
                >
                  <img src="@/assets/images/slideshare.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'http://www.corporationwiki.com/search/results?term='+searchLeadDetails.Company_Name"
                  target="_blank"
                >
                  <img src="@/assets/images/corporationwiki.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'http://www.whostalkin.com/search?q='+searchLeadDetails.Company_Name"
                  target="_blank"
                >
                  <img src="@/assets/images/whostalkin.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'http://news.google.com/news/search?aq=f&pz=1&cf=all&ned=us&hl=en&q='+searchLeadDetails.Company_Name"
                  target="_blank"
                >
                  <img src="@/assets/images/googlenews.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'http://www.google.com/search?hl=en&q='+searchLeadDetails.Company_Name+'&btnG=Google+Search'"
                  target="_blank"
                >
                  <img src="@/assets/images/googlelocal.png" />
                </a>
              </li>
              <li>
                <a
                  :href="'http://blogsearch.google.com/blogsearch?hl=en&q='+searchLeadDetails.Company_Name"
                  target="_blank"
                >
                  <img src="@/assets/images/googleblocksearch.png" />
                </a>
              </li> -->
            </ul>
          </div>
        </vs-tab>
        <vs-tab label="Contacts" class="contact-notification" :tag="contactLength" @click="activeTab = 4">
          <div class="tab-text">    
            <div class="tab-text page-view">
              <div class="tab-title">
                <h3>Possible Contacts</h3>
              </div>
              <vs-table class="summary-table" :data="searchLeadDetails.Contacts">
                <template slot="thead">
                  <vs-th>Contact Name</vs-th>
                  <vs-th>Title</vs-th>
                  <vs-th>Phone</vs-th>
                  <vs-th>Email</vs-th>
                  <vs-th></vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].Company_Contact">{{data[indextr].Company_Contact}}</vs-td>
                    <vs-td :data="data[indextr].Title">{{data[indextr].Title}}</vs-td>
                    <vs-td width="144px" :data="data[indextr].Phone_1">{{data[indextr].Phone_1}}</vs-td>
                    <vs-td :data="data[indextr].Email">{{data[indextr].Email}}</vs-td>
                    <vs-td>
                      <a v-show="data[indextr].Company_Contact" :href="'https://www.linkedin.com/search/results/people/?keywords='+data[indextr].Company_Contact+' '+searchLeadDetails.Company_Name+'&origin=FACETED_SEARCH'" target="_blank" title="Linkdin">
                        <feather-icon icon="LinkedinIcon"></feather-icon
                      ></a>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Keywords" @click="activeTab = 5">
          <div class="tab-text">
            <div class="tab-text page-view">
              <div class="tab-title">
                <h3>Summary of Keywords</h3>
              </div>
              <vs-table class="summary-table" :data="keywords">
                <template slot="thead">
                  <vs-th>Keyword</vs-th>
                  <vs-th width="80">count</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].Keyword">{{data[indextr].Keyword}}</vs-td>
                    <vs-td
                      width="80"
                      align="center"
                      :data="data[indextr].Keyword_Count"
                    >{{data[indextr].Keyword_Count}}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Campaign Info" @click="activeTab = 6">
          <div class="tab-text page-view">
            <div class="tab-title">
              <h3>Campaign Information</h3>
            </div>
            <vs-table class="summary-table campaign-table" :data="searchLeadDetails">
              <template slot-scope="{data}">
                <vs-tr>
                  <vs-th width="200">Campaign Name</vs-th>
                  <vs-td :data="data.Source_Campaign_Name">{{ data.Source_Campaign_Name }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th width="200">Campaign Source</vs-th>
                  <vs-td :data="data.Source_Campaign_Source">{{ data.Source_Campaign_Source }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th width="200">Campaign Term</vs-th>
                  <vs-td :data="data.Source_Campaign_Term">{{ data.Source_Campaign_Term }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th width="200">Campaign Content</vs-th>
                  <vs-td :data="data.Source_Campaign_Content">{{ data.Source_Campaign_Content }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th width="200">Campaign Medium</vs-th>
                  <vs-td :data="data.Source_Campaign_Medium">{{ data.Source_Campaign_Medium }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-tab>
      </vs-tabs>
      <vs-button
        @click="closepopup"
        color="grey"
        type="border"
        class="mt-6 mb-8 float-right grey-btn"
      >{{ LabelConstant.buttonLabelClose }}</vs-button>
    </vs-popup>
  </div>
</template>
<script>
    import vSelect from 'vue-select'
    import { Validator } from "vee-validate";

	const dict = {
		custom: {
			forwardProspectEmail: {
				required: "Please enter email",
				email: "Please enter valid email"
			}
		}
	};

	Validator.localize("en", dict);
    export default { 
        components: {
            vSelect
        }, 
        props: {
            latestVisitorId: {
                type: String,
                required: true
            },
            detailspopupActive: {
                type: Boolean,
                required: true
            },
            searchLeadDetailsData: {
                type: Object,
                required: true
            },
            pageViewsData: {
                type: Object,
                required: true
            },
            keywordsData: {
                type: Array,
                required: true
            },
            allVisitorUniqueDetailShortData: {
                type: Array,
                required: true
            }
        },
        computed: {
            searchLeadDetails: {
                get() { return this.searchLeadDetailsData },
                set(searchLeadDetails) {this.$emit('inputSearchLeadDetails', searchLeadDetails)}
            },
            pageViews: {
                get() { return this.pageViewsData },
                set(pageViews) {this.$emit('inputPageViews', pageViews)}
            },
            keywords: {
                get() { return this.keywordsData },
                set(keywords) {this.$emit('inputKeywords', keywords)}
            },
            allVisitorUniqueDetailShort: {
                get() { return this.allVisitorUniqueDetailShortData },
                set(allVisitorUniqueDetailShort) {this.$emit('inputAllVisitorUniqueDetailShort', allVisitorUniqueDetailShort)}
            },
            uniqueVisitorCount: function(){
                if(this.allVisitorUniqueDetailShortData){
                    return this.allVisitorUniqueDetailShortData.length
                }else{
                    return 0
                }
            },
            contactLength:function(){
                var x =document.getElementsByClassName("vs-tabs--li");
                if(this.searchLeadDetails && this.searchLeadDetails.Contacts){
                    if(x.length > 0 ){
                        document.querySelector('.vs-tabs--li:nth-last-child(3) button.vs-tabs--btn-tag i').innerHTML = this.searchLeadDetails.Contacts.length.toString();
                    }
                } 
                else {
                    if(x.length >0){
                      document.querySelector('.vs-tabs--li:nth-last-child(3) button.vs-tabs--btn-tag i').innerHTML = '0';
                    }
                    return '0'
                }
            },
        },
        data() {
            return {
                activeTab: 0,
                //Show company details or not
                detailsShow: true,

                //selected visitors
                selectedUniqueVisitors: [],
                //Show unique visitors or not show
                filterShow:false,

                //Forward prospect popup open or close
                forwardopupActive: false,
                //Forward prospect email
                forwardProspectEmail: null,
                
                //Tag prospect popup open or close
                tagpopupActive:false,
                //Show Extednded Fields
                isShowExtendedField: false,
                //Tag prospect data
                tagProspectDetails: {},
                gender: [
                    { genderName: "Male" },
                    { genderName: "Female"},
                ],

                isCRMEnabled: null
            }
        },
        mounted(){
          this.getLoggedInUser()
        },
        methods: {
            closepopup(){
                this.activeTab = 0
                this.$emit("close")
            },
            //Cancel Forward Prospect
            cancelForwardProspect(){
                this.forwardopupActive = false
                this.forwardProspectEmail = null
                setTimeout(() => { 
                    this.errors.clear('forwardProspectForm')
                }, 20);
            },

            //Mark visitor as an ISP
            updateCompanytoISP(){
                this.$vs.loading()
                let updateCompanytoISPParams = {
                  ISP_Name: this.searchLeadDetails.Company_Name, 
                  Match_Type: "exact" 
                }
                this.axios.post("/ws/ConfigFilterCompanies/AddISPEntry", updateCompanytoISPParams).then(() => {
                    this.searchLeadDetails.Company_is_isp = true
                    this.$vs.notify({
                        title:'Success',
                        text: 'You have successfully added a known ISP entity. Please allow up to 24 hours for your account to reflect the change.',
                        color: "success",
                        iconPack: 'feather',
                        icon:'icon-check',
                        position: "top-right",
                        time:4000
                    });
                    this.$vs.loading.close()
                }).catch(e => {
                    this.$vs.loading.close()
                    this.showError(e);
                    this.$vs.notify({
                        title:'Error',
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: 'feather',
                        icon:'icon-alert-circle',
                        position:"top-right",
                        time:4000
                    });
                });
            },
            //Mark visitor as a Company
            updateISPtoCompany(){
                this.$vs.loading()
                let updateISPtoCompanyParams = {
                  ISP_Name: this.searchLeadDetails.Company_Name, 
                  Match_Type: "exact" 
                }
                this.axios.post("/ws/ConfigFilterCompanies/AddBusinessEntry", updateISPtoCompanyParams).then(() => {
                    this.searchLeadDetails.Company_is_isp = false
                    this.$vs.notify({
                        title:'Success',
                        text: 'You have successfully added a known business entity. Please allow up to 24 hours for your account to reflect the change.',
                        color: "success",
                        iconPack: 'feather',
                        icon:'icon-check',
                        position: "top-right",
                        time:4000
                    });
                    this.$vs.loading.close()
                }).catch(e => {
                    this.$vs.loading.close()
                    this.showError(e);
                    this.$vs.notify({
                        title:'Error',
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: 'feather',
                        icon:'icon-alert-circle',
                        position:"top-right",
                        time:4000
                    });
                });
            },
            //Email information to someone
            forwardProspect(){
                let forwardProspectParams = {
                    Visitor_Company_Id: this.latestVisitorId,
                    Email: this.forwardProspectEmail
                }
                this.$validator.validateAll('forwardProspectForm').then(result => {
                    if (result) {
                        this.$vs.loading()
                        this.axios.post("/ws/SearchLead/ForwardProspect", forwardProspectParams).then(() => {
                            this.cancelForwardProspect()
                            this.$vs.notify({
                                title:'Success',
                                text: 'Your prospect has been forwarded.',
                                color: "success",
                                iconPack: 'feather',
                                icon:'icon-check',
                                position: "top-right",
                                time:4000
                            });
                            this.$vs.loading.close()
                        }).catch(e => {
                            this.$vs.loading.close()
                            this.showError(e);
                            this.$vs.notify({
                                title:'Error',
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: 'feather',
                                icon:'icon-alert-circle',
                                position:"top-right",
                                time:4000
                            });
                        });
                    }
                })
            },
            //Update watch list
            updateWatchList(companyName){
                this.$vs.loading()
                this.axios.get("/ws/SearchLead/UpdateWatchList?id="+companyName).then(() => {
                    this.searchLeadDetails.On_Watch_List = true
                    this.searchLeadDetails.On_Exclude_List = false
                    this.$vs.notify({
                        title:'Success',
                        text: 'Your watch list has been updated.',
                        color: "success",
                        iconPack: 'feather',
                        icon:'icon-check',
                        position: "top-right",
                        time:4000
                    });
                    this.$vs.loading.close()
                }).catch(e => {
                    this.$vs.loading.close()
                    this.showError(e);
                    this.$vs.notify({
                        title:'Error',
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: 'feather',
                        icon:'icon-alert-circle',
                        position:"top-right",
                        time:4000
                    });
                });
            },
            //Update Exclude list
            updateExcludeList(companyName){
                this.$vs.loading()
                this.axios.get("/ws/SearchLead/UpdateExcludeList?id="+companyName).then(() => {
                    this.searchLeadDetails.On_Exclude_List = true
                    this.searchLeadDetails.On_Watch_List = false
                    this.$vs.notify({
                        title:'Success',
                        text: 'Your exclude list has been updated.',
                        color: "success",
                        iconPack: 'feather',
                        icon:'icon-check',
                        position: "top-right",
                        time:4000
                    });
                    this.$vs.loading.close()
                }).catch(e => {
                    this.$vs.loading.close()
                    this.showError(e);
                    this.$vs.notify({
                        title:'Error',
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: 'feather',
                        icon:'icon-alert-circle',
                        position:"top-right",
                        time:4000
                    });
                });
            },
            //get tag prospect details
            getSearchLeadDetail(){
                this.$vs.loading()
                this.axios.get("/ws/SearchLead/GetSearchLeadDetail?id="+this.latestVisitorId).then(response => {
                    let data = response.data;
                    this.tagProspectDetails = data
                    this.tagProspectDetails.Gender = {genderName:this.tagProspectDetails.Gender}
                    
                    this.$vs.loading.close()
                }).catch(e => {
                    this.$vs.loading.close()
                    this.showError(e);
                });
            },
            //Update tag prospect
            updateVisitorUniqueDetail(){
                this.tagProspectDetails.Gender = this.tagProspectDetails.Gender.genderName
                this.$vs.loading()
                this.axios.post("/ws/SearchLead/UpdateVisitorUniqueDetail", this.tagProspectDetails).then(() => {
                    this.isShowExtendedField = false
                    this.$emit("getCompanyDetails")
                    
                    this.$vs.loading.close()
                }).catch(e => {
                    this.$vs.loading.close()
                    this.showError(e);
                });   
            },
            //get Filtered visitor Page Views
            getFilteredPageViews(){
                let visitorIds = []
                for (let i = 0; i < this.selectedUniqueVisitors.length; i++) {
                    visitorIds.push(this.selectedUniqueVisitors[i].Visitor_Id[0])
                }   
                let getFilteredPageViewsParams = {
                    Visitor_Company_Id: this.latestVisitorId,
                    Visitor_Ids: visitorIds
                }
                this.$vs.loading()
                this.axios.post("/ws/SearchLead/GetFilteredPageViews", getFilteredPageViewsParams).then(response => {
                    let data = response.data;
                    this.pageViews = data
                    
                    this.$vs.loading.close()
                }).catch(e => {
                    this.$vs.loading.close()
                    this.showError(e);
                });
            },

            getLoggedInUser() {
              this.$vs.loading();
              this.axios.get("/ws/ConfigUser/GetLoggedInUser").then(response => {
                  let data = response.data;
                  this.isCRMEnabled = data.CRM_Enabled
                  this.$vs.loading.close();
              }).catch(e => {
                  this.$vs.loading.close();
                  this.showError(e);
              });
            },

            sendToCRM(id){
              this.$vs.loading();
              this.axios.get("/ws/SearchLead/SendToCrm?id="+id).then(response => {
                  this.$vs.loading.close();
                  this.$vs.notify({
                    title:'Success',
                    text: response.data,
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                  });
              }).catch(e => {
                  this.$vs.loading.close();
                  this.showError(e);
                  this.$vs.notify({
                    title:'Error',
                    text: e.response.data,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                  });
              });  
            }
        },
    }
</script>